<template>
<b-overlay :show="loading" rounded="sm">
  <section>
    <!-- bg-white -->
    <b-row class="">
      <b-col lg="3" class="ll-spanBox">
        <span
          class="ll-tkid"
          v-if="(orderItem.orderQty === 1 || orderItem.orderQty === null) "
        >
          {{ orderItem.tkID }}
        </span>
        <b-form-select
          v-if="(orderItem.orderQty !== 1 && orderItem.orderQty !== null) "
          size="m"
          class="ll-pg"
          @input="name => selSonList(name, index)"
          style="font-size: 18px; font-weight: bold;width: 70%"
        >
        <b-form-select-option v-for="(i,k) in sonList" :key="k" :value="i">
        <span>{{i.tkID}}</span>
        </b-form-select-option>
        </b-form-select>
        <b-button
          variant="outline-primary"
          class="ml-1"
          id="popover-reactive-1"
          style="width: 25%"
          @click="getSignature"
        >signature</b-button>
      </b-col>
      <b-col lg="4" class="ll-spanBox">
        <span>{{ latestOrderStatus }}</span>
        <b-badge
          pill
          class="ll-badge"
          :variant="orderItem.tags === 'DELIVERED' ? 'light-info' :'light-danger'"
          v-if="tags !== null"
        >
          {{tags.toLowerCase().replace(/[_,]/g, ' ')}}
        </b-badge>
      </b-col>
      <b-col lg="4" class="ll-spanBox">
        <span>{{ customerName }} {{merchantCode}}</span>
      </b-col>
    </b-row>
    <!-- bg-white -->
    <b-row class="ll-hr">
      <b-col lg="3" class="ll-Sedfloor ll-line">
        <p class="ll-pTit">{{ $t('General') }}</p>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Customer order no') }}:</label>
          <span class="ll-span">{{ customerOrderNumber }}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Order date') }}:</label>
          <span class="ll-span">{{orderID}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Thrid Party Order No') }}:</label>
          <span class="ll-span">{{kuaidiBytkId}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Package qty') }}:</label>
          <span class="ll-span">{{ pkSn }}{{ orderQty }}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Item qty') }}:</label>
          <span class="ll-span">{{itemQty}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Customer Type') }}:</label>
          <span class="ll-span">{{orgType}}</span>
        </div>
        <div class="ll-box" v-if="isshowAmount">
          <label class="ll-lab">{{ $t('FOD Amount') }}:</label>
          <span class="ll-span">{{fodValue1? Number(fodValue1).toFixed(2):fodValue1}}</span>
          <v-select
            v-model="fod"
            :options="currencyList"
            label="currencyFrom"
            @input="selUnit"
            class="ll-unit"
            :clearable="false"
          ></v-select>
        </div>
        <div class="ll-box" v-if="isshowAmount1">
          <label class="ll-lab">{{ $t('COD Amount') }}:</label>
          <span class="ll-span">{{codValue1? Number(codValue1).toFixed(2):codValue1}}</span>
          <v-select
            v-model="cod"
            :options="currencyList"
            label="currencyFrom"
            @input="selUnit1"
            class="ll-unit"
            :clearable="false"
          ></v-select>
        </div>
      </b-col>
      <b-col lg="3" class="ll-Sedfloor ll-line">
        <p class="ll-pTit">{{ $t('Delivery worksheet') }}</p>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Delivery method') }}:</label>
          <span class="ll-span">{{deliveryType}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Payment method') }}:</label>
          <span class="ll-span">{{paymentMethod}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Delivery attempts') }}:</label>
          <span class="ll-span">{{ attempts }}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Number of returns') }}:</label>
          <span class="ll-span">{{ numberOfReturn }}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Last mile label') }}:</label>
          <b-link :href="labelUrl" target="_blank">
            <svg class="icon ll-imgs" aria-hidden="true">
              <use xlink:href="#icon-Waybill"></use>
            </svg>
          </b-link>
        </div>
      </b-col>
      <b-col lg="6" class="ll-Sedfloor">
        <b-row>
          <b-col lg="6">
            <p class="ll-pTit">{{ $t('Ship from') }}</p>
            <div class="ll-p1">
              <p>{{ fromAddress }} {{info.fromDistinct}}</p>
              <p>{{ fromCity }} {{fromState}} {{info.fromZip}}</p>
              <p>{{ info.fromCountry }} {{ fromCountryCode }}</p>
              <p>{{ info.fromName }}</p>
              <p>{{ info.fromPhone?info.fromPhone:'N/A' }}</p>
              <p>{{ info.fromOrgPhone?info.fromOrgPhone:'N/A' }}</p>
            </div>
          </b-col>
          <b-col lg="6">
            <p class="ll-pTit">{{ $t('Ship to') }}</p>
            <div class="ll-p1">
              <p>{{ caddress }} {{ info.cdistinct }}</p>
              <p>{{ ccity }} {{info.cstate}} {{info.czip}}</p>
              <p>{{ info.ccountry }} {{ ccountryCode }}</p>
              <p>{{ info.cname }}</p>
              <p>{{ info.cphone }}</p>
              <p>{{ info.cemail }}</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <!-- background: #ffffff; -->
      <b-col lg="12" style=" margin-top: 10px;">
        <p class="ll-pTit ll-pt">{{ $t('Track & Trace') }}</p>
        <div v-if="initStatus" class="ll-wrapper">
          <app-timeline v-for="(item, index) in orderStatusList" :key="index">
            <app-timeline-item
              variant="info"
              class="ll-timeline"
              v-if="item.statusType === 'NORMAL'"
            >
              <div class="ll-sbox">
                <span class="ll-time">{{item.eventTime}}</span>
                <span class="ll-desBox"
                  style="color: #409eff; background: #c5c5c51f;"
                >
                  {{item.statusDesc}}
                  {{((item.statusCode == 'DELIVERED') || item.originStatusDesc) ? '(' : ''}}
                  {{item.statusCode == 'DELIVERED' ? (customerOrderNumber + ' ' +  (item.originStatusDesc ? item.originStatusDesc : '')) : (item.originStatusDesc)}}
                  {{((item.statusCode == 'DELIVERED') || item.originStatusDesc) ? ')' : ''}}
                  <!-- {{item.statusDesc}} {{item.originStatusDesc ? '('+item.originStatusDesc+')' : null}} -->
                </span>
              </div>
            </app-timeline-item>
            <app-timeline-item
              variant="secondary"
              class="ll-timeline"
              v-if="item.statusType === 'MESSAGE'"
            >
              <div class="ll-sbox">
                <span class="ll-time">{{item.eventTime}}</span>
                <span class="ll-desBox"
                  style="color: #6e6b7b; background: #c5c5c51f"
                >
                  {{item.statusDesc}} {{item.originStatusDesc!== null ? '('+item.originStatusDesc+')' : null}}
                </span>
              </div>
            </app-timeline-item>
            <app-timeline-item
              variant="danger"
              class="ll-timeline"
              v-if="item.statusType === 'EXCEPTION'"
            >
              <div class="ll-sbox">
                <span class="ll-time">{{item.eventTime}}</span>
                <span class="ll-desBox"
                  style="color: #ea5455; background: #c5c5c51f"
                >
                  {{item.statusDesc}} {{item.originStatusDesc!== null ? '('+item.originStatusDesc+')' : null}}
                </span>
              </div>
            </app-timeline-item>
          </app-timeline>
        </div>
      </b-col>
    </b-row>
    <b-popover
      ref="popover"
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="bottom"
      container="my-container"
    >
      <template v-slot:title>
        <div class="d-flex justify-content-between align-items-center">
          <span>Electronic Signature</span>
        </div>
      </template>
      <swiper
        class="swiper-navigations"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(data,index) in swiperData"
          :key="index"
        >
          <b-img
            :src="data"
            fluid
          />
          <span class="ll-signTime">{{signTime}}</span>
        </swiper-slide>

        <!-- Add Arrows -->
        <div
          slot="button-next"
          class="swiper-button-next"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
      </swiper>
    </b-popover>
  </section>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BLink, BBadge, BOverlay, BFormSelect, BFormSelectOption, BButton,
  BPopover, BCarousel, BCarouselSlide, BImg,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import axios from 'axios'
import vSelect from 'vue-select'
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/css/swiper.css"

export default {
  components: {
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    AppTimeline,
    AppTimelineItem,
    BLink,
    BBadge,
    BOverlay,
    vSelect,
    BButton,
    BPopover,
    BCarousel,
    BCarouselSlide,
    Swiper,
    SwiperSlide,
    BImg,
  },
  props: {
    orderItem: Object,
    jobDetails1: Object,
    jobDetails2: Object,
    jobDetails3: Array,
    jobDetails4: Array,
  },
  data() {
    return {
      latestOrderStatus: '',
      status: '',
      customerOrderNumber: '',
      attempts: '',
      numberOfReturn: '',
      packageQty: '',
      fromAddress: '',
      fromCity: '',
      fromCountry: '',
      fromName: '',
      fromPhone: '',
      fromOrgPhone: '',
      fromState: '',
      fromZip: '',
      toAddress: null,
      toCity: null,
      toCountry: null,
      toName: null,
      toState: null,
      toZip: null,
      dipTime: null,
      compTime: null,
      traTime: null,
      prodTime: null,
      recTime: null,
      tableHei: document.documentElement.clientHeight*0.3,
      orderID: '',
      itemQty: '',
      orgType: '',
      fodValue: null,
      fodValue1: null,
      fodCurrency: null,
      currencyList: [],
      fod: null,
      codValue: null,
      codValue1: null,
      codCurrency: null,
      cod: null,
      cdistinct: '',
      senderPhone: '',
      timeColor1: 'secondary',
      timeColor2: 'secondary',
      timeColor3: 'secondary',
      timeColor4: 'secondary',
      timeColor5: 'secondary',
      eadDate: '',
      paymentMethod: '',
      col1: '#6e6b7b',
      col2: '#6e6b7b',
      col3: '#6e6b7b',
      col4: '#6e6b7b',
      col5: '#6e6b7b',
      info: {},
      caddress: null,
      ccity: null,
      ccountryCode: null,
      fromCountryCode: null,
      cstate: null,
      customerName: null,
      initStatus: false,
      orderStatusList: [],
      labelUrl: null,
      orderQty: null,
      pkSn: null,
      isFirst: null,
      merchantCode: null,
      deliveryType: null,
      tags: null,
      loading: true,
      kuaidiBytkId: null,
      isshowAmount: false,
      isshowAmount1: false,
      sonList: [],
      popoverShow: false,
      swiperData: [
        // {
        //   img: 'https://dev-hkdelivery.s3.ap-east-1.amazonaws.com/img/KECHKTEST0100000002_20220307093159904.jpeg',
        //   time: '2022-03-08 23:59:59'
        // },
        // { img: require('@/assets/images/banner/banner-4.jpg'),time: '2022-03-08 23:59:59' },
      ],
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      signTime: null,
    }
  },
  computed: {
  },
  watch: {
    jobDetails1(val){
      this.getinfo1(val)
      this.getKuaidiBytkId(this.orderItem.tkID)
    },
    jobDetails2(val){
      this.getinfo2(val)
    },
    jobDetails3(val){
      this.getinfo3(val)
    },
    jobDetails4(val){
      this.getinfo4(val)
    },
  },
  created() {
  },
  mounted() {
    this.queryCurrency()
    // this.getSignature() 
  },
  methods: {
    getSignature() {
      this.$http.get(`/signature/getSignature?tkID=${this.orderItem.tkID}`).then(res => {
        const info = res.data.data
        this.swiperData = info.esignLabel
        this.signTime = info.eventTime
      })
    },
    getKuaidiBytkId(tkid) {
      this.$http.get(`/kuaidi/getKuaidiBytkId?tkId=${tkid}`).then(res => {
        this.kuaidiBytkId = res.data.data
      })
    },
    getinfo1(info) {
      this.caddress = (info.caddress !== null && info.cdistinct !== null && info.caddress !== '' && info.cdistinct !== '') ? (info.caddress + ',') : info.caddress
      this.ccity = (info.ccity !== null && info.ccity !== '') ? (info.ccity + ',') : info.ccity
      this.ccountryCode = (info.ccountryCode !== null && info.ccountryCode !=='') ? ('(' + info.ccountryCode + ')') : info.ccountryCode
      this.fromAddress = (info.fromAddress !== null && info.fromAddress !=='') ? (info.fromAddress + ',') : info.fromAddress
      this.fromCity = (info.fromCity !== null && info.fromCity !== '') ? (info.fromCity + ',') : info.fromCity
      this.fromCountryCode = (info.fromCountryCode !== null && info.fromCountryCode !== '') ? ('(' + info.fromCountryCode + ')') : info.fromCountryCode
      this.fromState = (info.fromState !== null && info.fromState !=='') ? (info.fromState + ',') : info.fromState
      this.cstate = (info.cstate !== null && info.cstate !== '') ? (info.cstate + ',') : info.cstate
      this.info= info

      this.customerOrderNumber = info.customerOrderNumber
      this.querySonList()
      this.packageQty = info.packages.length
      this.itemQty = info.packages[0].itemqty
      this.eadDate = info.eadDate
      if (info.paymentMethod == 'PP') {
        this.paymentMethod = 'Not require COD Service'
      } else if (info.paymentMethod == 'COD') {
        this.paymentMethod = 'COD Service Required'
      }
      this.orderQty = info.orderQty
      this.pkSn = (info.packages[0].pkSn !== null) ? (info.packages[0].pkSn + '/') : info.packages[0].pkSn
      this.isFirst = info.packages[0].pkSn
      this.merchantCode = (info.merchantCode !== null && info.merchantCode !=='') ? ('(' + info.merchantCode +')') : info.merchantCode
      this.fodValue = info.fodValue
      this.fodValue1 = info.fodValue
      this.fodCurrency = info.fodCurrency
      this.fod = info.fodCurrency
      if (info.fodValue == null || info.fodValue == '') {
        this.isshowAmount = false
      } else {
        this.isshowAmount = true
      }
      this.loading = false
    },
    getinfo2(info) {
      this.tags = info.tag[0]
      this.labelUrl = `${axios.defaults.baseURL}/label/downLoadFile/${info.tkID}`
      this.orderID = info.createdAt
      this.customerName = info.customerName
      this.attempts = info.deliveryAttempts
      this.numberOfReturn = info.numberOfReturns
      if (info.deliveryType == 'HD') {
        this.deliveryType = 'Delivery'
      } else if (info.deliveryType == 'SP') {
        this.deliveryType = 'Self Pick'
      }
      this.orgType = info.orgType
      if (this.codeValue)
      this.codValue = info.codValue
      this.codValue1 = info.codValue
      this.codCurrency = info.codCurrency
      this.cod = info.codCurrency
      if (info.codValue == null || info.codValue == '') {
        this.isshowAmount1 = false
      } else {
        this.isshowAmount1 = true
      }
      switch (info.latestOrderStatus) {
        case 'COMP':
          this.latestOrderStatus = 'Completed'
          break
        case 'REC':
          this.latestOrderStatus = 'Order received'
          break
        case 'PROD':
          this.latestOrderStatus = 'On Warehouse'
          break
        case 'DIP':
          this.latestOrderStatus = 'Delivery in progress'
          break
        default:
          // this.latestOrderStatus = 'Last'
          break
      }
    },
    getinfo3(info) {
      this.orderStatusList = info
      if (this.orderStatusList.length > 0) {
        this.initStatus = true
      }
    },
    getinfo4(orderStatus) {
      this.orderStatusList = orderStatus
      if (this.orderStatusList.length > 0) {
        this.initStatus = true
      }
      orderStatus.map((item, index) => {
        switch (item.orderStatus) {
          case 'COMP':
            if (item.statusType === 'NORMAL') {
              this.timeColor1 = 'info'
              this.compTime = item.eventTime
            } else {
              this.timeColor1 = 'danger'
              this.col1 = '#ff0000'
            }
            break
          case 'REC':
            if (item.statusType === 'NORMAL') {
              this.timeColor5 = 'info'
              this.recTime = item.eventTime
            } else {
              this.timeColor5 = 'danger'
              this.col5 = '#ff0000'
            }
            break
          case 'PROD':
            if (item.statusType === 'NORMAL') {
              this.timeColor3 = 'info'
              this.prodTime = item.eventTime
            } else {
              this.timeColor3 = 'danger'
              this.col3 = '#ff0000'
            }
            break
          case 'DIP':
            if (item.statusType === 'NORMAL') {
              this.timeColor2 = 'info'
              this.dipTime = item.eventTime
            } else {
              this.timeColor2 = 'danger'
              this.col2 = '#ff0000'
            }
            break
          case 'TRA':
            if (item.statusType === 'NORMAL') {
              this.timeColor4 = 'info'
              this.traTime = item.eventTime
            } else {
              this.timeColor4 = 'danger'
              this.col4 = '#ff0000'
            }
            break
          default:
            console.log(item.eventTime)
            break
        }
      })
    },
    querySonList() {
      this.$http.get('/jobkes/getSonList', { params: { customerOrderNo: this.customerOrderNumber } }).then(res => {
        this.sonList = res.data.data
      })
    },
    selSonList(d,i) {
      console.log(d,i)
      this.tkid = d.tkID
      this.orderId = d.orderID
      this.nums = d.pkSN
      this.idx = i
    },
    // track trace
    clearStatus() {
      this.dipTime = ''
      this.compTime = ''
      this.traTime = ''
      this.prodTime = ''
      this.recTime = ''
      this.timeColor1 = 'secondary'
      this.timeColor2 = 'secondary'
      this.timeColor3 = 'secondary'
      this.timeColor4 = 'secondary'
      this.timeColor5 = 'secondary'
      this.col1 = '#6e6b7b'
      this.col2 = '#6e6b7b'
      this.col3 = '#6e6b7b'
      this.col4 = '#6e6b7b'
      this.col5 = '#6e6b7b'
    },
    queryCurrency() {
      this.$http.get('currency/selectAll').then(res=>{
        this.currencyList = res.data.data
      })
    },
    selUnit(d) {
      this.fod = d.currencyFrom
      this.$http.get(`/currency/calCulaTion?currencyFrom=${this.fodCurrency}&value=${this.fodValue}&curremcuTo=${d.currencyFrom}`).then(res=>{
        this.fodValue1 = res.data.data
      })
    },
    selUnit1(d) {
      this.cod = d.currencyFrom
      this.$http.get(`/currency/calCulaTion?currencyFrom=${this.codCurrency}&value=${this.codValue}&curremcuTo=${d.currencyFrom}`).then(res=>{
        this.codValue1 = res.data.data
      })
    },
  },
}
</script>
<style scoped>
.ll-signTime{
  display: block;
  text-align: center;
}
.ll-signImg{
  width: 300px;
  height: 200px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 2rem;
}
.ll-unit{
  width: 100px;
  height: 30px;
}
.ll-badge{
  margin-left: 1rem;
  text-transform: capitalize;
  font-size: 16px !important;
}
.ll-spanBox{
  margin: 10px 0;
}
.ll-spanBox span{
  font-size: 18px;
  font-weight: bold;
}
.ll-box{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.ll-box .ll-lab{
  color: #6e6b7b;
}
.ll-pTit{
  color: #ed6d01;
  font-size: 14px;
}
.ll-line{
  border-right: 1px solid #d0d2d6;
}
/* .ll-p{
  font-weight: 500;
  color: #5e5873;
} */
.ll-p p{
  margin-bottom: 0.2857rem;
}
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
}
.ll-Sedfloor{
  margin-bottom: 10px;
}
.ll-trackStatus{
  width: 80%;
  border: 1px solid #eee;
  height: 28px;
  display: flex;
}
.ll-colorSpan{
  display: inline-block;
  width: 2%;
  height: 20px;
  background: #4B7902;
}
.ll-desSpan{
  line-height: 20px;
  padding-left: 20px;
}
.ll-wrapper{
  /* display: flex; */
  padding-top: 10px;
  height: 30vh;
  overflow-y: auto;
}
.ll-wrap{
  width: 100%;
}
.ll-timeline{
  height: 50px;
}
.ll-tl{
 margin-bottom: 15px;
}
.ll-pt{
  margin: 20px 0;
}
.ll-sbox{
  display: flex;
}
.ll-time{
  width: 100px;
  white-space: break-spaces;
  display: block;
}
.ll-desBox{
  display: block;
  width: 80%;
  padding: 10px;
  /* border-left: 5px solid #ed6d01; */
  border-radius: 5px;
}
.ll-apptime{
  width: 80%;
}
.ll-imgs{
  width: 25px;
  height: 25px;
}
@media (min-width :2000px){
  .ll-wrapper {
    height: 39vh;
  }
}
.custom-select {
  padding: 0.4rem 2rem 0.4rem 1rem
}
</style>
